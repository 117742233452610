<template>
  <div>
    <ViewTemplateConfiguration
      :templateList="templateList"
      :showSearch="false"
      :showHorizontalFilter="false"
      :propsParam="propsParam"
    >
      <div slot="content-buttons">
        <Button
          _key="createAddress"
          title="Endereço"
          classIcon="fas fa-plus-circle"
          type="primary"
          size="small"
          eventName="createAddress"
          :clicked="createAddress"
        />
      </div>
    </ViewTemplateConfiguration>
    <Modal
      title="Endereço"
      :width="900"
      :height="750"
      v-show="showModal('createUpdateAddressCustomerSupplier')"
    >
      <AddressCreateUpdate :genericId="genericId" />
    </Modal>
  </div>
</template>

<script>
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import AddressCreateUpdate from "./AddressCreateUpdate.vue";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "AddressList",
  components: {
    ViewTemplateConfiguration,
    Modal,
    Button,
    AddressCreateUpdate,
  },
  props: {
    genericId: String,
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/shared/address/get-all",
        urlDeleteAllApi: "/api/v1/shared/address/delete",
        headerTable: [
          {
            field: "description",
            title: "Descrição",
            type: "link",
            eventName: "updateAddress",
          },
          {
            field: "formattedAddress",
            title: "Endereço",
            type: "text",
          },
          {
            field: "mainName",
            title: "Principal",
            type: "text",
          },
        ],
      },
      propsParam: {
        any: this.genericId,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "removeLoading"]),
    createAddress() {
      this.openModal("createUpdateAddressCustomerSupplier");
      this.removeLoading(["createAddress"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "updateAddress")
          this.openModal("createUpdateAddressCustomerSupplier");
      },
      deep: true,
    },
  },
};
</script>
