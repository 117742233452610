<template>
  <div>
    <b-row>
      <b-col sm="5">
        <InputText title="Descrição" field="descricao" :formName="formName" :required="true" :maxLength="50"
          v-model="address.description" :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <AddressValueObject :formName="formName" :required="true" v-model="address.addressValueObject" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <CheckboxSimple title="Principal" :markFormDirty="false" v-model="address.main" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button _key="addressCreateUpdate" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="create" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import AddressValueObject from "@nixweb/nixloc-ui/src/component/value-objects/Address.vue";
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple.vue";

import Address from "@/components/modules/shared/address/Address.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "AddressCreateUpdate",
  props: {
    genericId: String,
    registerInSelect: Boolean,
    value: Object,
  },
  components: { Button, InputText, AddressValueObject, CheckboxSimple },
  data() {
    return {
      formName: "addressCreateUpdate",
      address: new Address(),
      urlCreate: "/api/v1/shared/address/create",
      urlUpdate: "/api/v1/shared/address/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", [
      "removeLoading",
      "removeEvent",
      "hideModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    create() {
      let addressRequest = this.addressRequest();
      if (this.address.id) {
        let params = {
          url: this.urlUpdate,
          obj: addressRequest,
        };
        this.putApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          this.removeLoading(["addressCreateUpdate"]);
        });
      } else {
        let params = {
          url: this.urlCreate,
          obj: addressRequest,
        };
        this.postApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          if (response.success && this.registerInSelect) {
            this.$emit("input", {
              id: response.content.id,
              content: response.content.description,
            });
            this.hideVodal(this.formName);;
          }
          this.removeLoading(["addressCreateUpdate"]);
        });
      }
    },
    update(data) {
      this.address = new Address();
      this.address.update(data);
      this.removeLoading([data.id]);
    },
    addressRequest() {
      return {
        id: this.address.id,
        description: this.address.description,
        addressValueObjectRequest: {
          zipCode: this.address.addressValueObject.zipCode,
          street: this.address.addressValueObject.street,
          number: this.address.addressValueObject.number,
          complement: this.address.addressValueObject.complement,
          province: this.address.addressValueObject.province,
          city: this.address.addressValueObject.city,
          state: this.address.addressValueObject.state.content,
        },
        main: this.address.main,
        genericId: this.genericId,
      };
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "createAddress") {
          this.address = new Address();
          this.resetValidation(this.formName);
        }
        if (event.name == "updateAddress") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
