export default class Address {
    constructor() {
        this.id = "";
        this.description = "";
        this.addressValueObject = {
            zipCode: "",
            street: "",
            number: "",
            complement: "",
            province: "",
            city: "",
            state: { id: "", content: "" },
        };
        this.main = false;
        this.customerSupplierId = "";
    }
    update(data) {
        this.id = data.id;
        this.description = data.description;
        this.addressValueObject = {
            zipCode: data.addressResponse.zipCode,
            street: data.addressResponse.street,
            number: data.addressResponse.number,
            complement: data.addressResponse.complement,
            province: data.addressResponse.province,
            city: data.addressResponse.city,
            state: { id: data.addressResponse.state.content, content: data.addressResponse.state.content },
        };
        this.main = data.main;
        this.customerSupplierId = data.customerSupplierId;
    }
}